import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import { useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import set from 'lodash/set';
import { salesforceStore } from '../store/salesforce-store';
import {
  populateAppData,
  populateAppDataFromSalesforceLead,
  populateAppDataFromSavedQuote
} from '../utils/populate-app-data';
import { useFirebase } from '../components/Firebase';
import { SCHEME_CODE, MASTER_CODE, OCCUPATION_FAMILY_CODE } from '../constants/constants';
import { quoteStore } from '../store/quote-store';
import { stepStore } from '../store/step-store';
import { mtaStore } from '../store/mta-store';
import { lookupStore } from '../store/lookup-store';
import { setContainerIFrameHeight } from '../utils/set-container-iframe-height';
import { getUtmFields } from '../utils/get-utm-fields';
import { renewalStore } from '../store/renewal-store';
import { stateStore } from '../store/state-store';
import { populateLegalAddons } from '../utils/helper-functions';

const moment = require('moment-timezone');

export const cacheKeys = {
  lead: 'lead',
  opportunity: 'opportunity',
  quote: 'quote',
  policy: 'policy',
  lookups: 'lookups'
};

const isToday = someDate => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const reportError = (firebase, err, id, func = undefined) => {
  const error = {
    message: err.message,
    details: err.details,
    code: err.code,
    function: func,
    scheme: SCHEME_CODE
  };

  Sentry.captureException(err, {
    function: func,
    scheme: SCHEME_CODE,
    id
  });
  firebase.doReportErrorToYoutrack({ error, id });
};

export const useLookups = () => {
  const firebase = useFirebase();
  const history = useHistory();
  return useQuery(
    [cacheKeys.lookups],
    async () => {
      const lookups = await firebase.doGetLookups(SCHEME_CODE);
      Object.entries(lookups).forEach(([key, value]) => {
        set(lookupStore, key, value);
      });
      setTimeout(setContainerIFrameHeight, 500);
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: err => {
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'useLookups');
        history({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};
export const updateMarketingOptions = async (firebase, formik) => {
  const { values } = formik;
  if (salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId) {
    firebase.doUpdateMarketingOptions({
      opportunityId: salesforceStore.salesforceOpportunityId,
      leadId: salesforceStore.salesforceLeadId,
      marketingOptions: JSON.stringify(values.marketing),
      email: values.email
    });
  }
};

export const useQuote = (id, userId) => {
  const firebase = useFirebase();
  const history = useHistory();
  const formik = useFormikContext();
  const queryClient = useQueryClient();
  return useQuery(
    [cacheKeys.quote],
    async () => {
      const [quoteData, lookups] = await Promise.all([
        firebase.doGetQuote(id, userId),
        queryClient.fetchQuery(cacheKeys.lookups, () => firebase.doGetLookups(SCHEME_CODE))
      ]);
      Object.entries(lookups).forEach(([key, value]) => {
        set(lookupStore, key, value);
      });
      const { quoteRef } = quoteData;
      quoteStore.prevQuote = quoteRef;
      populateAppDataFromSavedQuote(formik, quoteData);
    },
    {
      onError: err => {
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'updateMarketingOptions');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      },
      staleTime: Infinity
    }
  );
};

export const useQuoteInfoFromLeadID = id => {
  const firebase = useFirebase();
  const history = useHistory();
  const formik = useFormikContext();
  const queryClient = useQueryClient();

  return useQuery(
    [cacheKeys.lead],
    async () => {
      const [quoteData, lookups] = await Promise.all([
        firebase.doGetQuoteInfoFromLeadID({
          leadID: id,
          scheme: SCHEME_CODE
        }),
        queryClient.fetchQuery(cacheKeys.lookups, () => firebase.doGetLookups(SCHEME_CODE))
      ]);
      Object.entries(lookups).forEach(([key, value]) => {
        set(lookupStore, key, value);
      });
      populateAppDataFromSalesforceLead(formik, quoteData);
      history.replace(`/lead/${salesforceStore.salesforceLeadId}${history.location.search}`);
    },
    {
      staleTime: Infinity,
      onError: err => {
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'useQuoteInfoFromLeadID');
        salesforceStore.setIsSalesforceWorking(false);
        history.replace({
          pathname: '/form',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useQuoteInfoFromOpportunityID = id => {
  const firebase = useFirebase();
  const history = useHistory();
  const formik = useFormikContext();
  const queryClient = useQueryClient();

  return useQuery(
    [cacheKeys.opportunity],
    async () => {
      const [quoteData, lookups] = await Promise.all([
        firebase.doGetQuoteInfoFromOpportunityID({
          opportunityID: id,
          scheme: SCHEME_CODE
        }),
        queryClient.fetchQuery(cacheKeys.lookups, () => firebase.doGetLookups(SCHEME_CODE))
      ]);
      Object.entries(lookups).forEach(([key, value]) => {
        set(lookupStore, key, value);
      });
      populateAppDataFromSalesforceLead(formik, quoteData);
      localStorage.removeItem(get(quoteData, 'opportunityId'));
      history.replace(`/opportunity/${salesforceStore.salesforceOpportunityId}${history.location.search}`);
    },
    {
      staleTime: Infinity,
      onError: err => {
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'useQuoteInfoFromOpportunityID');
        salesforceStore.setIsSalesforceWorking(false);
        history.replace({
          pathname: '/form',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useCreateInitialQuote = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const location = useLocation();
  const { utmValues, otherValues } = getUtmFields(location);
  const { setIsQuoteSaved } = stateStore;
  const queryClient = useQueryClient();

  return useMutation(
    async values => {
      const lookups = await queryClient.fetchQuery(cacheKeys.lookups, () => firebase.doGetLookups(SCHEME_CODE));
      Object.entries(lookups).forEach(([key, value]) => {
        set(lookupStore, key, value);
      });
      history.push(`/form${history.location.search}`);
      return firebase.doCreateInitialQuote(
        {
          OccupationFamily: OCCUPATION_FAMILY_CODE,
          ...values,
          ...utmValues,
          ...otherValues
        },
        SCHEME_CODE,
        salesforceStore.salesforceLeadId,
        salesforceStore.salesforceOpportunityId
      );
    },
    {
      onSuccess: async resultInitial => {
        quoteStore.quoteRef = resultInitial.quoteRef;
        salesforceStore.salesforceLeadId = resultInitial.salesforceLeadId;
        salesforceStore.salesforceOpportunityId = resultInitial.salesforceOpportunityId;
        salesforceStore.salesforceContactId = resultInitial.salesforceContactId;
        setIsQuoteSaved(false);
        if (resultInitial.salesforceOpportunityId) {
          await queryClient.fetchQuery(cacheKeys.opportunity, async () => {
            const result = await firebase.doGetQuoteInfoFromOpportunityID({
              opportunityID: resultInitial.salesforceOpportunityId,
              scheme: SCHEME_CODE
            });
            salesforceStore.saveSalesforceData(result);
          });
          localStorage.removeItem(resultInitial.salesforceOpportunityId);
          history.replace(`/opportunity/${resultInitial.salesforceOpportunityId}${history.location.search}`);
        } else if (resultInitial.salesforceLeadId) {
          await queryClient.fetchQuery(cacheKeys.lead, async () => {
            const result = await firebase.doGetQuoteInfoFromLeadID({
              leadID: resultInitial.salesforceLeadId,
              scheme: SCHEME_CODE
            });
            salesforceStore.saveSalesforceData(result);
          });
          history.replace(`/lead/${resultInitial.salesforceLeadId}${history.location.search}`);
        }
      },

      onError: err => {
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'useCreateInitialQuote');
        salesforceStore.setIsSalesforceWorking(false);
        history.push({ pathname: '/error', state: { message: err.message } });
      }
    }
  );
};

export const useCreateInitialMta = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const formikRef = useFormikContext();
  const { values } = formikRef;
  return useMutation(
    async () => {
      const mtaResult = await firebase.doCreateInitialMTA({
        policyReference: mtaStore.policyReference,
        mtaStartDate: isToday(new Date(values.changeDate))
          ? moment().valueOf()
          : moment.tz(moment(values.changeDate).format('YYYY-MM-DD'), 'Europe/London').valueOf(),
        mtaReason: values.changeReason.code,
        userId: quoteStore.userId
      });
      mtaStore.mta = JSON.parse(mtaResult.data.mta);
      mtaStore.saveQuoteData(JSON.parse(mtaResult.data.mta));
      populateAppData(formikRef);
    },
    {
      onSuccess: () => {
        window.scrollTo(0, 0);
        formikRef.setTouched({}, false);
        stepStore.goToStep('chooseStep');
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, mtaStore.policyId, 'useCreateInitialMta');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useRateQuote = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const formik = useFormikContext();
  const { values } = formik;
  const limitOfIndemnity = get(values, 'LimitOfIndemnity');
  console.log("In HEREEE:")
  return useMutation(
    async () => {
      console.log("In HEREEE222:")

      if (limitOfIndemnity.code === '10000000') {
        history.replace('/referral');
        return;
      }
      const result = await firebase.doRateQuote(
        {
          ...values,
          firstName: values.firstName || salesforceStore.firstName,
          lastName: values.lastName || salesforceStore.lastName,
          email: values.email || salesforceStore.email,
          phone: values.phone || salesforceStore.phone,
          dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          PrevInsurerExpiryDate: values.PrevInsurerExpiryDate
            ? moment(values.PrevInsurerExpiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : '',
          inceptionDate: moment(values.EffectivePeriodStartDate).format('YYYY-MM-DD'),
          SalesforceLeadID: salesforceStore.salesforceLeadId,
          SalesforceID: salesforceStore.salesforceOpportunityId,
          SalesforceContactID: salesforceStore.salesforceContactId,
          product: SCHEME_CODE,
          companyMasterReference: MASTER_CODE,
          marketing: values.marketing
        },
        quoteStore.quoteRef,
        quoteStore.prevQuote
      );
      console.log("Result::", result)
      quoteStore.saveQuoteData(result);
      populateLegalAddons(formik, result);
      if (quoteStore.referrals || quoteStore.declines) {
        history.replace('/referral');
      }
    },
    {
      onSuccess: () => {
        stepStore.nextStep();
        window.scrollTo(0, 0);
        salesforceStore.rateLoader = false;
      },
      onError: err => {
        console.log("Error:", err)
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'useRateQuote');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useRepriceQuote = () => {
  const firebase = useFirebase();
  const history = useHistory();
  return useMutation(
    async () => {
      const result = await firebase.doRepriceQuote(
        JSON.stringify(quoteStore.getSelectedAddons()),
        quoteStore.quoteRef,
        quoteStore.underwritingResponseId,
        SCHEME_CODE,
        salesforceStore.salesforceOpportunityId
      );
      quoteStore.saveQuoteData(JSON.parse(result));
    },
    {
      onError: err => {
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'useRepriceQuote');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useRepriceRenewal = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const formik = useFormikContext();

  return useMutation(
    async () => {
      quoteStore.isSubmitting = true;
      const result = await firebase.doRepriceRenewal({
        request: JSON.stringify(quoteStore.getSelectedAddons()),
        transactionId: renewalStore.renewalTransaction,
        policyReference: renewalStore.policyId
      });
      const decoded = JSON.parse(result.data);
      quoteStore.saveQuoteData(decoded);
      renewalStore.saveQuoteData(decoded);
      populateLegalAddons(formik, decoded);
      quoteStore.isSubmitting = false;
    },

    {
      onError: err => {
        console.error(err);
        reportError(firebase, err, renewalStore.policyId, 'useRepriceRenewal');
        history.push({ pathname: '/error', state: { message: err.message } });
      }
    }
  );
};

export const useRateMTA = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const { values } = useFormikContext();
  const limitOfIndemnity = get(values, 'LimitOfIndemnity');

  return useMutation(
    async () => {
      if (limitOfIndemnity.code === '10000000') {
        history.replace('/referral');
        return;
      }
      const result = await firebase.doRateMTA({
        ...values,
        dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        PrevInsurerExpiryDate: values.PrevInsurerExpiryDate
          ? moment(values.PrevInsurerExpiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : '',
        product: SCHEME_CODE,
        companyMasterReference: MASTER_CODE,
        mtaStartDate: isToday(new Date(values.changeDate))
          ? moment().valueOf()
          : moment.tz(moment(values.changeDate).format('YYYY-MM-DD'), 'Europe/London').valueOf(),
        mtaReason: values.changeReason.code,
        policyReference: mtaStore.policyId,
        mta: JSON.stringify(mtaStore.mta)
      });
      if (
        get(JSON.parse(result.data.underWriteMTA), 'underwritingOutcome.underwrittenOptions[0].optionState') ===
        'HAS_UNAUTHORISED_REFERRALS'
      ) {
        history.replace('/referral');
        return;
      }
      quoteStore.setEndorsements(
        get(
          JSON.parse(result.data.underWriteMTA).underwritingOutcome,
          'underwrittenOptions[0].underwrittenEndorsements'
        )
      );
      mtaStore.saveQuoteData(JSON.parse(result.data.underWriteMTA));
      if (mtaStore.referrals || mtaStore.declines) {
        history.replace('/referral');
      }
    },

    {
      onSuccess: () => {
        window.scrollTo(0, 0);
        stepStore.goToStep(6);
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, mtaStore.policyId, 'useRateMTA');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useSaveQuote = () => {
  const firebase = useFirebase();
  const history = useHistory();
  return useMutation(
    () =>
      firebase.doSaveQuote({
        quote: {
          product: SCHEME_CODE,
          companyMasterReference: MASTER_CODE,
          quoteRef: quoteStore.quoteRef
        },
        rateBody: { ...quoteStore.requestData },
        prevQuote: quoteStore.prevQuote,
        userId: quoteStore.userId,
        underwritingResponseId: quoteStore.underwritingResponseId,
        optionId: quoteStore.optionId
      }),
    {
      onSuccess: async result => {
        quoteStore.saveQuoteData(JSON.parse(result));
        if (quoteStore.prevQuote) {
          quoteStore.prevQuote = quoteStore.quoteRef;
        }
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, quoteStore.quoteRef, 'useSaveQuote');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useSavePolicy = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const location = useLocation();
  const formik = useFormikContext();
  const { values } = formik;
  const { utmValues: utm } = getUtmFields(location);
  const sessionId = get(window, 'AutopilotAnywhere.sessionId', '');
  return useMutation(
    () =>
      firebase.doSavePolicy({
        request: {
          ...values,
          dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          PrevInsurerExpiryDate: values.PrevInsurerExpiryDate
            ? moment(values.PrevInsurerExpiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : '',
          inceptionDate: moment(values.EffectivePeriodStartDate).format('YYYY-MM-DD'),
          utm,
          SalesforceLeadID: salesforceStore.salesforceLeadId,
          SalesforceContactID: salesforceStore.salesforceContactId,
          SalesforceID: salesforceStore.salesforceOpportunityId,
          product: SCHEME_CODE,
          companyMasterReference: MASTER_CODE,
          promotionalCode: values.promotionalCode
        },
        addonCodes: quoteStore.getSelectedAddonCodes(),
        quoteRef: quoteStore.quoteRef,
        prevQuote: quoteStore.prevQuote,
        paymentCode: values.paymentType,
        authorisationId: values.stripe.authorisationId,
        bankDetails: values.payment,
        sessionId,
        userId: quoteStore.userId,
        paymentId: quoteStore.paymentId
      }),
    {
      onSuccess: async result => {
        quoteStore.policyId = result.policyId;
        localStorage.setItem('previousQuote', '');
        history.replace('/success');
      },
      onError: err => {
        console.error(err);
        if (err.details && err.details.code === 'bank-not-validated') {
          formik.setErrors({
            payment: {
              accountName: "Please check the account holder's name for accuracy",
              bankName: "Please check the back's name for accuracy.",
              accountNumber: 'Please double-check the account number',
              sortCode: 'Please verify the sort code'
            }
          });
          quoteStore.apiError =
            err.details.message ||
            'Card declined, please contact your bank or credit card company for further details as to why the payment is not being processed before trying again';

          return;
        }
        reportError(firebase, err, quoteStore.quoteRef, 'useSavePolicy');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};
export const useTakeUpMTA = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const { values } = useFormikContext();
  return useMutation(
    async () => {
      window.scrollTo(0, 0);
      const mtaResult = await firebase.doRateMTA({
        ...values,
        dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        PrevInsurerExpiryDate: values.PrevInsurerExpiryDate
          ? moment(values.PrevInsurerExpiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : '',
        product: SCHEME_CODE,
        companyMasterReference: MASTER_CODE,
        mtaStartDate: isToday(new Date(values.changeDate))
          ? moment().valueOf()
          : moment.tz(moment(values.changeDate).format('YYYY-MM-DD'), 'Europe/London').valueOf(),
        mtaReason: values.changeReason.code,
        policyReference: mtaStore.policyId,
        mta: JSON.stringify(mtaStore.mta)
      });
      const paymentCodes = get(
        JSON.parse(mtaResult.data.underWriteMTA),
        'underwritingOutcome.underwrittenOptions[0].paymentCollectionOptions'
      );
      const rateResultData = JSON.parse(mtaResult.data.underWriteMTA);
      mtaStore.mtaReference = get(rateResultData, 'transactionId');
      const optionId = get(rateResultData, 'underwritingOutcome.underwrittenOptions[0].optionId');
      if (stepStore.step === 10) {
        const chargeId = get(quoteStore, 'paymentId');
        const paymentType = get(mtaStore, 'paymentType');
        await firebase.doTakeUpMTA(
          mtaStore.policyId,
          mtaStore.mtaReference,
          optionId,
          paymentCodes,
          chargeId,
          paymentType
        );
      } else {
        await firebase.doTakeUpMTA(
          mtaStore.policyId,
          mtaStore.mtaReference,
          optionId,
          paymentCodes,
          undefined,
          mtaStore.hasCloseBrothersOption ? 'CLOSE_BROTHERS' : undefined
        );
      }
    },
    {
      onSuccess: () => {
        history.replace('/success');
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, mtaStore.policyId, 'useTakeUpMTA');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useCreateInitialRenewal = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const formikRef = useFormikContext();
  return useMutation(
    async () => {
      const [renewalResult, lookups] = await Promise.all([
        firebase.doCreateInitialRenewal({
          policyReference: renewalStore.policyReference
        }),
        firebase.doGetLookups(SCHEME_CODE)
      ]);
      Object.entries(lookups).forEach(([key, value]) => {
        set(lookupStore, key, value);
      });
      renewalStore.renewal = JSON.parse(renewalResult.data.renewal);
      renewalStore.saveQuoteData(JSON.parse(renewalResult.data.renewal));
      populateAppData(formikRef);
    },
    {
      onSuccess: () => {
        window.scrollTo(0, 0);
        formikRef.setTouched({}, false);
        stepStore.goToStep('renewalPage');
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, renewalStore.policyId, 'useCreateInitialRenewal');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useGetPolicyDate = () => {
  const firebase = useFirebase();
  // const formikRef = useFormikContext();
  const history = useHistory();

  return useMutation(
    async id => {
      const result = await firebase.doGetPolicy(id);
      return result;
    },
    {
      onSuccess: async result => {
        const mtaDate = new Date(JSON.parse(result).transactionData.effectiveStartDateTime);
        const lastChangeDate = mtaDate > new Date() ? mtaDate : new Date();
        set(lookupStore, 'lastChangeDate', lastChangeDate);

        const renewalDate = new Date(JSON.parse(result).transactionData.effectiveEndDateTime);
        const calendarEndDate = moment().add(30, 'days').toDate();
        const renewalStartDate = renewalDate < calendarEndDate ? renewalDate : calendarEndDate;
        set(lookupStore, 'renewalStartDate', renewalStartDate);
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, mtaStore.policyId || renewalStore.policyId, 'useGetPolicyDate');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useRateRenewal = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const formik = useFormikContext();
  const { values } = formik;
  const limitOfIndemnity = get(values, 'LimitOfIndemnity');

  return useMutation(
    async () => {
      if (limitOfIndemnity.code === '10000000') {
        history.replace('/referral');
        return;
      }
      const result = await firebase.doRateRenewal({
        ...values,
        dateOfBirth: moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        PrevInsurerExpiryDate: values.PrevInsurerExpiryDate
          ? moment(values.PrevInsurerExpiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : '',
        product: SCHEME_CODE,
        companyMasterReference: MASTER_CODE,
        renewalTransaction: renewalStore.renewalTransaction,
        policyReference: renewalStore.policyId,
        renewal: JSON.stringify(renewalStore.renewal)
      });
      quoteStore.saveQuoteData(JSON.parse(result.data.underWriteRenewal));
      renewalStore.saveQuoteData(JSON.parse(result.data.underWriteRenewal));
      populateLegalAddons(formik, JSON.parse(result.data.underWriteRenewal));
      if (quoteStore.referrals || quoteStore.declines) {
        history.replace('/referral');
      }
    },
    {
      onSuccess: () => {
        window.scrollTo(0, 0);
        stepStore.goToStep(6);
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, renewalStore.policyId, 'useRateRenewal');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};

export const useTakeUpRenewal = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const { values } = useFormikContext();
  return useMutation(
    async () => {
      window.scrollTo(0, 0);
      if (stepStore.step === 10) {
        const chargeId = get(quoteStore, 'paymentId');
        const paymentType = get(renewalStore, 'paymentType');
        await firebase.doTakeUpRenewal(
          renewalStore.policyId,
          renewalStore.renewalTransaction,
          renewalStore.optionId,
          renewalStore.payments,
          chargeId,
          values.payment,
          paymentType
        );
      } else {
        await firebase.doTakeUpRenewal(
          renewalStore.policyId,
          renewalStore.renewalTransaction,
          renewalStore.optionId,
          renewalStore.payments,
          undefined,
          renewalStore.hasCloseBrothersOption ? 'CLOSE_BROTHERS' : undefined
        );
      }
    },
    {
      onSuccess: () => {
        history.replace('/success');
      },
      onError: err => {
        console.error(err);
        reportError(firebase, err, renewalStore.policyId, 'useTakeUpRenewal');
        history.push({
          pathname: '/error',
          state: { message: err.message }
        });
      }
    }
  );
};
